.App {
	margin: 0;
	padding: 0;
	max-width: 100vw;
}

ul {
	padding: 0 0 10px 20px;
}

footer.ui.secondary.segment {
	border-radius: 0;
	margin-top: 20px;
}

.react-photo-gallery--gallery > div > img {
	align-items: flex-start !important;
	object-fit: cover;
}

img {
	align-items: flex-start !important;
	object-fit: cover;
}